import React from "react";
import { Link } from "gatsby-plugin-react-i18next";

import img4 from "../../assets/images/blog/blog-img4.jpg";
import img5 from "../../assets/images/blog/blog-img5.jpg";
import img6 from "../../assets/images/blog/blog-img6.jpg";
import img10 from "../../assets/images/blog/blog-img10.jpg";
import user1 from "../../assets/images/user1.jpg";
import user2 from "../../assets/images/user2.jpg";
import user3 from "../../assets/images/user3.jpg";
import user6 from "../../assets/images/user6.jpg";

const BlogCard = () => {
	return (
		<div className="blog-area ptb-100">
			<div className="container">
				<div className="row">
					<div className="col-lg-4 col-md-6">
						<div className="single-blog-post bg-fffbf5">
							<div className="post-image">
								<Link to="https://www.linkedin.com/pulse/candidate-screening-automation-yannick-molinier/">
									<img src={img4} alt="Blog" />
								</Link>
							</div>

							<div className="post-content">
								<ul className="post-meta d-flex justify-content-between align-items-center">
									<li>
										<div className="post-author d-flex align-items-center">
											<img
												src={user1}
												className="rounded-circle"
												alt="Blog"
											/>
											<span>Yannick MOLINIER</span>
										</div>
									</li>
									<li>
										<i className="flaticon-calendar"></i>{" "}
										Mar.8, 22<sup>Mise à jour</sup>
									</li>
								</ul>
								<h3>
									<li>
										Connecting a «LinkedIn Job» POST to
										CROWLR Job & Talent Pool campaign !
									</li>
								</h3>
							</div>
						</div>
					</div>

					<div className="col-lg-4 col-md-6">
						<div className="single-blog-post bg-fffbf5">
							<div className="post-image">
								<Link to="/parrainage-digital-via-cooptation-et-ambassadeurs">
									<img src={img5} alt="Blog" />
								</Link>
							</div>

							<div className="post-content">
								<ul className="post-meta d-flex justify-content-between align-items-center">
									<li>
										<div className="post-author d-flex align-items-center">
											<img
												src={user2}
												className="rounded-circle"
												alt="Blog"
											/>
											<span>Marei Thymme</span>
										</div>
									</li>
									<li>
										<i className="flaticon-calendar"></i>{" "}
										June 6, 2022<sup>Mise à jour</sup>
									</li>
								</ul>
								<h3>
									<Link to="/parrainage-digital-via-cooptation-et-ambassadeurs">
										Employee Advocacy: Pourquoi associer vos
										collaborateurs à votre stratégie de
										recrutement via un rôle d'ambassadeur
										pour représenter et défendre l'image de
										votre entreprise ?
									</Link>
								</h3>
							</div>
						</div>
					</div>

					<div className="col-lg-4 col-md-6">
						<div className="single-blog-post bg-fffbf5">
							<div className="post-image">
								<Link to="/matching-candidature-interactif-et-benchmark-employabilité">
									<img src={img6} alt="Blog" />
								</Link>
							</div>

							<div className="post-content">
								<ul className="post-meta d-flex justify-content-between align-items-center">
									<li>
										<div className="post-author d-flex align-items-center">
											<img
												src={user3}
												className="rounded-circle"
												alt="Blog"
											/>
											<span>Jacques Hady</span>
										</div>
									</li>
									<li>
										<i className="flaticon-calendar"></i>{" "}
										July 2, 2022<sup>Mise à jour</sup>
									</li>
								</ul>
								<h3>
									<Link to="/matching-candidature-interactif-et-benchmark-employabilité">
										"Les retours de nos collaborateurs a été
										un franc succès. Cela donne une image
										moderne de notre société qui propose un
										outil adapté...
									</Link>
								</h3>
							</div>
						</div>
					</div>

					{/*
                    <div className="col-lg-4 col-md-6">
                        <div className="single-blog-post bg-fffbf5">
                            <div className="post-image">
                                <Link to="/blog-details">
                                    <img src={img7} alt="Blog" />
                                </Link>
                            </div>

                            <div className="post-content">
                                <ul className="post-meta d-flex justify-content-between align-items-center">
                                    <li>
                                        <div className="post-author d-flex align-items-center">
                                            <img src={user6} className="rounded-circle" alt="Blog" />
                                            <span>Harry Cover</span>
                                        </div>
                                    </li>
                                    <li>
                                        <i className='flaticon-calendar'></i> April 8, 2022
                                    </li>
                                </ul>
                                <h3>
                                    <Link to="/blog-details">
                                        The data-driven approach to understanding
                                    </Link>
                                </h3>
                            </div>
                        </div>
                    </div>
                    */}
					{/*
                    <div className="col-lg-4 col-md-6">
                        <div className="single-blog-post bg-fffbf5">
                            <div className="post-image">
                                <Link to="/blog-details">
                                    <img src={img8} alt="Blog" />
                                </Link>
                            </div>

                            <div className="post-content">
                                <ul className="post-meta d-flex justify-content-between align-items-center">
                                    <li>
                                        <div className="post-author d-flex align-items-center">
                                            <img src={user6} className="rounded-circle" alt="Blog" />
                                            <span>Harry Cover</span>
                                        </div>
                                    </li>
                                    <li>
                                        <i className='flaticon-calendar'></i> April 9, 2022
                                    </li>
                                </ul>
                                <h3>
                                    <Link to="/blog-details">
                                        xxxxx
                                    </Link>
                                </h3>
                            </div>
                        </div>
                    </div>
                    */}

					<div className="col-lg-4 col-md-6">
						<div className="single-blog-post bg-fffbf5">
							<div className="post-image">
								<Link to="/innovative-recruiting-system-et-expérience-candidat-innovante">
									<img src={img10} alt="Blog" />
								</Link>
							</div>

							<div className="post-content">
								<ul className="post-meta d-flex justify-content-between align-items-center">
									<li>
										<div className="post-author d-flex align-items-center">
											<img
												src={user6}
												className="rounded-circle"
												alt="Blog"
											/>
											<span>Harry Cover</span>
										</div>
									</li>
									<li>
										<i className="flaticon-calendar"></i>{" "}
										April 10, 2022<sup>Mise à jour</sup>
									</li>
								</ul>
								<h3>
									<Link to="/innovative-recruiting-system-et-expérience-candidat-innovante">
										<a>
											Oui, créer la meilleure expérience
											de travail pour vos talents!
										</a>
									</Link>
								</h3>
							</div>
						</div>
					</div>

					<div className="col-lg-12 col-md-12">
						{/* Pagination */}

						{/*
                        <div className="pagination-area text-center">
                            <Link to="#" className="prev page-numbers">
                                <i className='bx bx-chevrons-left'></i>
                            </Link>
                            <span className="page-numbers current" aria-current="page">1</span>
                            <Link to="#" className="page-numbers">
                                2
                            </Link>
                            <Link to="#" className="page-numbers">
                                3
                            </Link>
                            <Link to="#" className="page-numbers">
                                4
                            </Link>
                            <Link to="#" className="next page-numbers">
                                <i className='bx bx-chevrons-right'></i>
                            </Link>
                        </div>
                        */}
					</div>
				</div>
			</div>
		</div>
	);
};

export default BlogCard;
